body {
    background: white;
    color: white;
}

.pr-7 {
    padding-right: 7rem !important;
}

.pl-7 {
    padding-left: 7rem !important;
}

.app {
    background: black;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -5;
}

.video {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}

.video iframe {
    width: 100vw;
    height: 100vh;
}

.skin {
    z-index: 1000;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.65);
    height: 100%;
    width: 100%;
}

.lower-skin {
    display: block;
    position: fixed;
    bottom: 10%;
    width: 86%;
    height: 42%;
}

.transparent {
    opacity: 0;
}

.noMovie {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    ;
}

.noMovie p {
    color: white;
    font-family: Josefin Sans, serif;
    letter-spacing: 4px;
    font-size: 1em;
    margin-bottom: 5px;
}

.report {
    margin-top: 18%;
}

.report-title {
    color: white;
    font-family: Josefin Sans, serif;
    letter-spacing: 4px;
    font-size: 1em;
    margin-bottom: 5px;
}

.report-submit {
    color: white;
    font-family: Josefin Sans, serif;
    letter-spacing: 4px;
    font-size: 0.6em;
    margin-bottom: 5px;
    cursor: pointer;
}

.report-submit:hover {
    font-weight: bold;
}

.mobile {
    color: black;
    font-family: Josefin Sans, serif;
    letter-spacing: 5px;
    font-size: 2em;
    margin-bottom: 5px;
}

/* .youtube-container {
    /* position: absolute; 
    overflow: hidden;
    width: 100%;
    height: 100%;
    /* Keep it the right aspect-ratio */
/* aspect-ratio: 16/9; */
/* No clicking/hover effects */
/* pointer-events: none; */
/* } */
/* 
.youtube-container iframe {
    width: 300%;
    /* transform:scale(1.4); */

    /* height: 100%; */
    /* ...and bring it back again */
    /* margin-left: -100%; */
/* } */ 

.youtube-wrapper {
    z-index: -100;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}


.youtube-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.youtube-wrapper iframe {
    pointer-events: none;
}

.youtube-wrapper iframe {
    position: absolute;
    top: -250px;
    left: 0;
    width: 100%;
    height: calc(100% + 500px);
}

.youtube-container {
    pointer-events: none;
}